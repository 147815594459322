.lay-out__container {
	// background: url('../../../assets/images/backgrounds/bg_slide_0.png');
	background: linear-gradient(#e4e5e7 70%, #82c341);
	// height: 100vh;
	// width: 1000vw;

	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	min-height: 100vh;
	min-width: 100vw;
}
