.image__container {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100vh;

	background: linear-gradient(#e4e5e7 70%, #82c341);
	.logo__main {
		width: 20%;
	}
}
