.registration__container {
	max-width: 70rem;
	display: flex;
	margin: auto;
	justify-content: center;
	align-items: center;
	height: 100vh;

	.form__control:not(:last-child) {
		margin-bottom: 2rem;
	}

	.form__control {
		position: relative;

		select,
		input {
			width: 100%;
			font-size: 2rem !important;
			outline: none;
			border: none;
			padding: 2rem;

			margin-bottom: 1rem;

			background-color: rgb(245, 245, 245);
			-webkit-appearance: none;
			-moz-appearance: none;
			appearance: none;

			&::-ms-expand {
				display: none;
			}
		}
		img {
			width: 25rem;
			position: relative;
			margin: auto;
			cursor: pointer;
		}
		.button__text {
			position: absolute;
			font-size: 3rem;
			color: #ffff;
			top: 19%;
			left: 28%;
			cursor: pointer;
		}
		select {
			background: url("../../assets/images/i.png") #ffff 95%;
			background-repeat: no-repeat;
		}
	}
	.error__msg {
		color: white;
		font-size: 2rem;
		text-align: center;
	}
}
.MuiAlert-standardError {
	background-color: #fce4e4 !important;
	border: 2px solid #fcc2c3 !important;
	color: #d8000c !important;
}
.MuiAlert-message {
	font-size: 12px !important;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	margin: 0;
}

@media screen and (max-device-height: 512px) {
	.registration__container {
		.form__control:not(:last-child) {
			margin-bottom: 0.5rem;
		}
	}
}
